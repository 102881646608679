<template>
  <div class="videoLibrary">
    <div class="navBar">
      <div class="backBtn">
<!--        <svg-icon iconClass="leftArrow"></svg-icon>-->
      </div>
      <div class="navTitle">视频</div>
    </div>
    <div class="main">
      <div class="selectBox">
        <div
          class="selectItem"
          @click="changeSort(item.value)"
          :class="{ selectActive: item.value == sort }"
          v-for="item in sorts"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="selectBox oepnX">
        <div
          class="selectItem"
          @click="changeLabel('')"
          :class="{ selectActive: !selectLabel }"
        >
          全部标签
        </div>
        <div
          class="selectItem mg3"
          @click="changeLabel(item)"
          :class="{ selectActive: item.choose }"
          v-for="item in labels"
          :key="item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="selectBox">
        <div
          class="selectItem"
          @click="changePlayVideoTime(item.value)"
          :class="{ selectActive: item.value == playVideoTime }"
          v-for="item in playVideoTimes"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="selectBox">
        <div
          class="selectItem"
          @click="changeVideoType(item.value)"
          :class="{ selectActive: item.value == videoType }"
          v-for="item in videoTypes"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="list">
        <Pullrefresh
          @onLoad="onLoad"
          :loading="loading"
          :finished="finished"
          @onRefresh="onRefresh"
          :refreshing="refreshing"
          :isNoData="isNoData"
          :firstLoading="firstLoading"
        >
          <div class="video-list flex-between">
            <videocover :item="item" v-for="item in list" :key="item.id" />
          </div>
        </Pullrefresh>
      </div>
    </div>
  </div>
</template>
<script>
import { queryLabels, queryVideoLibrary } from "@/api/shortVideo";
import videocover from "../widget/videoList.vue";
import Pullrefresh from "@/views/widget/PullRefresh.vue";

export default {
  name: "videoLibrary",
  components: {
    videocover,
    Pullrefresh,
  },
  data() {
    return {
      sort: "new",
      sorts: [
        {
          label: "最新",
          value: "new",
        },
        {
          label: "最热",
          value: "hot",
        },
      ],
      selectLabel: undefined,
      labels: [],
      playVideoTime: 0,
      playVideoTimes: [
        {
          label: "全部视频",
          value: 0,
        },
        {
          label: "长视频",
          value: 1,
        },
        {
          label: "短视频",
          value: 2,
        },
      ],
      videoType: 0,
      videoTypes: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "VIP",
          value: 1,
        },
        {
          label: "金币",
          value: 2,
        },
      ],
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 尺寸
      noData: false, //暂无数据
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
    };
  },
  created() {
    this.getLabels();
  },
  methods: {
    async getLabels() {
      let res = await this.$Api(queryLabels);
      if (res && res.code == 200) {
        this.labels = res.data || [];
      }
      this.getList();
    },
    async getList() {
      this.loading = true;
      let req = {
        tags: this.selectLabel,
        playVideoTime: this.playVideoTime,
        videoType: this.videoType,
        sort: this.sort,
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
      };
      let ret = await this.$Api(queryVideoLibrary, req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if (ret.code == 200 && ret.data && ret.data.mediaInfos) {
        let mediaInfos = ret.data.mediaInfos;
        this.list = this.list.concat(mediaInfos);
        if (mediaInfos.length == 0 && this.pageNumber == 1) {
          this.isNoData = true;
          return;
        }
        if (mediaInfos.length < this.pageSize) {
          this.finished = true;
        }
      } else {
        this.isNoData = true;
        return;
      }
    },
    onLoad() {
      this.pageNumber++;
      this.getList();
    },
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.pageNumber = 1;
      this.list = [];
      this.getList();
    },
    changeSort(value) {
      this.sort = value;
      this.onRefresh();
    },
    changeLabel(value) {
      if (!value) {
        this.selectLabel = undefined;
        this.labels = this.labels.map((item) => {
          item.choose = 0;
          return item;
        });
      } else {
        // this.labels = this.labels.map((item) => {
        //   if (item.id == value.id) {
        //     item.choose ? (item.choose = 0) : (item.choose = 1);
        //   }
        //   return item;
        // });
        // const flag = this.labels
        //   .map((item) => {
        //     if (item.choose) {
        //       return item.name;
        //     }
        //   })
        //   .filter((item) => item);
          this.selectLabel = undefined;
          this.labels = this.labels.map((item) => {
              item.choose = 0;
              return item;
          });
          this.labels = this.labels.map((item) => {
            if (item.id == value.id) {
              item.choose ? (item.choose = 0) : (item.choose = 1);
            }
            return item;
          });
        this.selectLabel = [value.name];
      }
      this.onRefresh();
    },
    changePlayVideoTime(value) {
      this.playVideoTime = value;
      this.onRefresh();
    },
    changeVideoType(value) {
      this.videoType = value;
      this.onRefresh();
    },
  },
};
</script>
<style lang="scss" scoped>
.videoLibrary {
  width: 100%;
  height: 100%;
  .navBar {
    display: flex;
    align-items: center;
    height: 44px;
    font-size: 18px;
    padding: 0 10px;
    background: $white1;
    margin-bottom: 4px;
    .backBtn {
      margin-right: 20px;
    }
    .navTitle {
      flex: 2;
      text-align: center;
      margin-right: 38px;
    }
  }
  .main {
    width: 100%;
    height: calc(100vh - 94px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    .selectBox {
      margin-bottom: 10px;
      padding-left: 14px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .selectItem {
        font-size: 14px;
        padding: 5px 12px;
        border-radius: 28px;
        color: #353535;
        flex-shrink: 0;
      }
      .mg3 {
        margin: 0 3px;
      }
      .selectActive {
        color: white;
        background-color: #f94545;
      }
    }
    .list {
      width: 100%;
      .video-list {
        flex-wrap: wrap;
        padding: 0 16px;
      }
    }
  }
}
</style>
